import React from 'react';
import { Helmet } from 'react-helmet';

export default function junctionxhanoi2021() {
  let Reader;
  if (typeof window !== `undefined`) {
    Reader = require('react-read-pdf').MobilePDFReader;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {Reader && (
        <Reader
          isShowHeader={false}
          url="/documents/junctionx_hanoi_2021.pdf"
        />
      )}
      <div style={{ marginTop: '15px' }}>
        <a
          style={{
            fontWeight: 'bold',
            textDecoration: 'none',
            color: 'turquoise',
          }}
          href="/documents/junctionx_hanoi_2021.pdf"
        >
          Download PDF
        </a>
        {'  |  '}
        <a
          target="_blank"
          rel="noreferrer"
          style={{
            fontWeight: 'bold',
            textDecoration: 'none',
            color: 'violet',
          }}
          href="https://forms.gle/HEsWsiuYK79H3Qt27"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
}
